import{get,put,post,getExel}from"./base";
import qs from "qs";
export function  getTitles(identify){
    return get('/v3/jobtitles',{identify,'pageNum':1,'pageSize':'9999'})
}
export function  getLoginDepartment(){
    return get('/v3/offices',{'pageNum':1,'pageSize':'9999'})
}
/**会员医院待清洗 **/
export function  getHospitalCleans(){
    return get('/v3/hospitalcleans')
}
//修改医院名称
export function putHospitalCleans(hospital_name,data){
    return put('/v3/hospitalclean/' + hospital_name,qs.stringify(data))
}
//置空医院名称
export function putCleanHospital(hospital_name){
    return put('/v3/hospital/clean/' + hospital_name)
}


/**会员科室待清洗 **/

export function  getOfficeCleans(pageNum){
    return get('/v3/officescleans',{pageNum})
}

export function putOfficeCleans(hospital_offices,data){
    return put('/v3/officesclean/' + hospital_offices,qs.stringify(data))
}
//置空医院名称
export function putCleanOffice(hospital_offices){
    return put('/v3/offices/clean/' + hospital_offices)
}

/**会员职称待清洗 **/

export function  getTitleCleans(pageNum){
    return get('/v3/jobtitlecleans',{pageNum})
}

export function putTitleCleans(job_title,data){
    return put('/v3/jobtitleclean/' + job_title,qs.stringify(data))
}
//置空职称
export function putTitleClean(job_title){
    return put('/v3/jobtitle/clean/' + job_title)
}
// 注册会员管理 -- 更新记录的表格
export function  getMemberUpdate(data){
    return get('/v3/yun/member/update',data)
}
// 注册会员管理 -- 导出更新记录
export function  getMemberExport(data){
    return getExel('/v3/yun/member/update/export',data)
}
// 注册会员管理 -- 导出列表
export function  getRegisterExport(data){
    return getExel('/v3/yun/Member/export',data)
}
// 注册会员管理 -- 医护人员
export function  getRegisterMedicExport(data){
    return getExel('/v3/yun/Member/export/medic',data)
}
// 注册会员管理 -- 医学生
export function  getStudentRegisterExport(data){
    return getExel('/v3/yun/Member/export/student',data)
}
// 注册会员管理 -- 其他/非义务从业者
export function  getOtherRegisterExport(data){
    return getExel('/v3/yun/Member/export/other',data)
}
// 注册会员管理 -- 身份不明人员
export function  getUnknownRegisterExport(data){
    return getExel('/v3/yun/Member/export/unknown',data)
}