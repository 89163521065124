<template>

  <!--  会员科室待清洗-->
  <div class="hospitalCleaning">
    <a-spin :indicator="indicator" :spinning="spinning">
      <div class="hospital_table">
        <a-button type="primary"  @click="refresh">刷新</a-button>
        <a-table :columns="columns" style="min-height: 500px" :data-source="InfoTable" :rowKey="(record,index) => {return index}"   :pagination="false"
        >
       <span slot="input" slot-scope="text,item">
          <a-select style="width: 180px;margin-right: 10px"  placeholder="选择一级科室" >
              <a-select-option v-for="(list,index) in options" :key="list.id" :value="list.title" @click="oneSave(item.title,index)">
                {{ list.title }}
              </a-select-option>
            </a-select>
          <a-select style="width: 180px;" v-model="item.nameEdit"  placeholder="选择二级科室">
              <a-select-option v-for="list in subOptions.zoffices"  :key="list.id" :value="list.title">
                {{ list.title }}
              </a-select-option>
            </a-select>
        </span>
          <span slot="operate" slot-scope="text,item">
            <a  style="margin-right: 10px" @click="saveOk(item.nameEdit,item.hospitalOffices)">保存</a>
            <a @click="blankOk(item.hospital_offices)">置空</a>
        </span>
        </a-table>
        <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
          <a-pagination v-model="pagination.current"
                        :total="pagination.total"
                        @change="paginationChange" />
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {
  getLoginDepartment,
  getOfficeCleans,
  putCleanOffice,
  putOfficeCleans
} from "@/service/dataCleaned_api";

export default {
  name: "departmentCleaning",
  data(){
    return{
      spinning:false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      options:[],
      subOptions:{},
      oneOffice:undefined,
      columns:[
        {
          title: '非科室名单的注册科室',
          dataIndex: 'hospitalOffices',
        },
        {
          title: '注册数量',
          dataIndex: 'count',
        },
        {
          title: '名称修改为',
          scopedSlots: { customRender: 'input' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      InfoTable:[
        {
          id:1,
          name:'请问',
          number:2
        }
      ],
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"会员科室待清洗")
  },
  mounted() {
    this.getLoginDepartment()
    this.getOfficeList()
  },
  methods:{
    //获取待清洗科室
    async getOfficeList(pageNum){
      this.spinning = true
      const Info = await getOfficeCleans(pageNum)
      if (Info.code === 0){
        this.InfoTable = Info.data.wyYy
        this.pagination.total = Info.data.count
      }else {
        this.$message.warning('接口连接出错' + Info.message)
      }
      this.spinning = false
    },
    //获取科室
    async getLoginDepartment(){
      const Info = await getLoginDepartment()
      this.options = Info.data.wySy
    },
    paginationChange(e){
      this.getOfficeList(e)
    },
    //选择一级科室
    oneSave(name,index){
       this.subOptions =this.options[index]
    },
    //保存
    async saveOk(e,name){
      if (e !== '' && e !== undefined && e !== null){
        const data = {
          offices : e
        }
        const save =await putOfficeCleans(name,data)
        if (save.code === 0){
          await this.getOfficeList(this.pagination.current)
          this.$message.success('保存成功')
        }
      }else {
        this.$message.error('请选择科室')
      }
    },
    //置空
    async blankOk(name){
      const save =await putCleanOffice(name)
      if (save.code === 0){
        await this.getOfficeList(this.pagination.current)
        this.$message.success('已置空')
      }else {
        this.$message.warning('接口出现错误' + save.message)
      }
    },
    //刷新
    refresh(){
      this.getOfficeList()
    }
  }
}
</script>

<style lang="scss" scoped>
.hospitalCleaning{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .hospital_table{
    height:100%;
    width: 98%;
    margin: 10px auto;
    padding: 15px;
    background-color: white;
  }
}
</style>
